import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateLastPage } from "../../redux/user";
export default function Render() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!pathname.includes("/api")) {
      dispatch(updateLastPage(pathname));
    }
  }, [pathname, dispatch]);
  return null;
}
