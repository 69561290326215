import React from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div className='new-bg text-justify'>
            <div className="container">
                <button aria-label="Back to events" className="ms-2 back-to-event border-0 d-flex align-items-center gap-2 py-4 pb-2" onClick={() => navigate(-1)}>
                    <IoMdArrowRoundBack size={22} className="first-line-icon" />
                    Back to Events
                </button>
                <div className="row d-flex align-items-center justify-content-center p-4 text-justify">
                    <p className="text-center terms-title">
                        <strong>
                            <span data-contrast="auto">About Us</span>
                        </strong>
                    </p>
                    <p>
                        <strong>Eventgem </strong>
                        {" "}
                        <span>
                            is an exclusive ticketing platform that showcases the vibrant hues of various community events from across the world.
                        </span>
                    </p>
                    <p>
                        <span>
                            We bring together eventgoers and organizers on one single platform to create a holistic event experience like no other.
                        </span>
                    </p>
                    <p>Here, every event is a gem etched in time, and you can be a part of it. </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Three Core Pillars, One Single Platform &mdash; Eventgem</strong>
                    </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Cultural Immersion</strong>
                    </p>
                    <p>
                        <span>Our culture-rich events, curated just for you, include a wide variety of experiences. ranging from traditional music concerts and dance performances to local exhibitions, comedy shows, and the latest pop culture entertainment. </span>
                    </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Secure & User-Friendly Interface</strong>
                    </p>
                    <p>
                        <span>Our priorities are simplicity, convenience and security. Whether you're booking tickets or listing your own event, our platform's intuitive design makes it easy.</span>
                    </p>
                    <p>
                        <span>Discover personalized event recommendations, connect with friends, and manage your bookings effortlessly. </span>
                    </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Relationship first</strong>
                    </p>
                    <p>
                        <span>Our culture-rich events, curated just for you, include a wide variety of experiences. ranging from traditional music concerts and dance performances to local exhibitions, comedy shows, and the latest pop culture entertainment. </span>
                    </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Cultural Immersion</strong>
                    </p>
                    <p>
                        <span>As a relationship-first organization, we listen to our organizers and eventgoers to provide the best possible experience.</span>
                    </p>
                    <p>
                        <span>Our dedicated support team is here to assist you at every stage of your Eventgem experience.</span>
                    </p>
                    <p><span>&nbsp;</span></p>
                    <p>
                        <strong>Discover your next unforgettable event with us!</strong>
                    </p>
                    <p><span>&nbsp;</span></p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
