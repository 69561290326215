import React, { useEffect, useState } from "react";
import "./css/Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/user";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
import axios from "axios";
import logomark from "../../Assets/Logomark.svg";
import { FcGoogle } from "react-icons/fc";
// import { FaFacebook } from "react-icons/fa";
// import { FaApple } from "react-icons/fa";

const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function Login() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inputValue = urlParams.get("inputValue") || 21;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    credentials: "",
  });
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [unverifiedUser, setUnverifiedUser] = useState();
  const [showVerifyUserLink, setShowVerifyUserLink] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata((prev) => {
      const updatedData = { ...prev, [name]: value };
      checkError(name, value, updatedData);
      return updatedData;
    });
  };

  const checkError = (fieldName = null, value = null, formData = logindata) => {
    let newErrors = { ...errors };
    let dataIsValid = true;

    const validateField = (name, val) => {
      if (name === "email") {
        if (!val.trim()) {
          newErrors.email = "Email is required";
          dataIsValid = false;
        } else if (!regForEmail.test(val)) {
          newErrors.email = "Invalid email";
          dataIsValid = false;
        } else {
          newErrors.email = "";
        }
      }

      if (name === "password") {
        if (!val.trim()) {
          newErrors.password = "Password is required";
          dataIsValid = false;
        } else {
          newErrors.password = "";
        }
      }
    };

    if (fieldName) {
      validateField(fieldName, value);
    } else {
      Object.keys(formData).forEach((key) => validateField(key, formData[key]));
    }

    setErrors(newErrors);
    return dataIsValid;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }
        await axios
          .post(`${devApi}/api/clientLogin`, logindata)
          .then((res) => {
            if (res.data.success) {
              dispatch(
                login({
                  token: res.data.token,
                  user: res.data.user,
                  eventId: inputValue,
                })
              );
              navigate(`/`);
              toast.success("Login Successful");
            } else {
              setErrors({ ...errors, credentials: res.data.message });
              // toast.error("Incorrect email address or password. Please try again");
              toast.error(`${res.data.message}`);
            }
          })
          .catch((error) => {
            if (error.response.status === 451) {
              setUnverifiedUser(error.response.data.user);
              setShowVerifyUserLink(true);
            }
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const handleDm = () => {
    navigate("/doormanagement");
  };
  useEffect(() => {
    const handlePopState = (e) => {
      window.history.go(1);
    };

    // Add event listener on mount
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    fetch(`${devApi}/api/googleAuthLogin`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrlGoogle(data.url))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="new-bg d-flex vh-100 justify-content-center align-items-center">
      <div className="container">
        <div className="logo d-flex justify-content-center align-items-center mb-3">
          <Link to={"/"}>
            <img
              src={logomark}
              alt="Eventgem"
              width={60}
              className="logomark-border"
            />
          </Link>
        </div>

        <div className="text-center my-3">
          <h4 className="signup-title">Log in to Your Account</h4>
          <p className="signup-desc">Access your events and tickets</p>
        </div>

        {/* Form */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              {/* Email Input */}
              <div className="form-group mb-3">
                <label htmlFor="email" className="signup-label mb-1">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control signup-input-field"
                  placeholder="Enter your email"
                  name="email"
                  onChange={updateData}
                  value={logindata.email}
                  id="email"
                  autoComplete="off"
                />
                <small className="text-danger">{errors.email}</small>
              </div>

              {/* Password Input */}
              <div className="form-group mb-3">
                <label
                  htmlFor="password"
                  className="signup-label mb-1 d-flex justify-content-between"
                >
                  <span>Password</span>
                </label>
                <div className="position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control signup-input-field pe-5"
                    placeholder="Enter Password"
                    name="password"
                    id="password"
                    onChange={updateData}
                    value={logindata.password}
                  />
                  <button
                    type="button"
                    className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye"></i>
                    ) : (
                      <i className="bi bi-eye-slash"></i>
                    )}
                  </button>
                </div>
                <small className="text-danger">{errors.password}</small>
              </div>

              {/* Forgot Password */}
              <div className="d-flex justify-content-end align-items-center my-3">
                {/* <div className="d-flex gap-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineRadio1"
                    value={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                  <span>Remember for 30 days</span>
                </div> */}
                <div className="d-flex align-items-center m-0">
                  <Link to="/forgotpassword" className="text-decoration-none">
                    <h6 className="forgot-text m-0">Forgot Password</h6>
                  </Link>
                </div>
              </div>
              <div className="d-flex my-2 gap-2 align-items-center justify-content-center">
                {/* Error for Credentials */}
                {errors.credentials && (
                  <small className="text-danger">
                    {errors.credentials}
                  </small>
                )}

                {/* Verify User Link */}
                {showVerifyUserLink && (
                  <Link to="/verifyemail" className="text-decoration-none" state={{ ...unverifiedUser }}>
                    <small className="custom-link-terms">Verify Email Now?</small>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Login Button */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <button className="signup-btn w-100" style={{ maxWidth: "400px" }} type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        ) : (
          <div className="container">
            <div className="d-flex justify-content-center align-items-center mt-2">
              <div className="w-100" style={{ maxWidth: '400px' }}>
                <button
                  className="btn w-100 get-started"
                  onClick={() => {
                    const isValid = checkError();
                    if (!isValid) {
                      return
                    }
                    logins();
                  }}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Sign Up Link */}
        <div className="container d-flex justify-content-center align-items-center mt-3 mb-4">
          <div className="text-center">
            <h5 className="already-account">
              Don't have an account?{" "}
              <Link to="/signup">
                <span className="login-link">Sign up</span>
              </Link>
            </h5>
          </div>
        </div>
        {/*<div className="d-flex flex-column my-4 justify-content-evenly align-items-center w-100">
              <div className="line"></div>
              <div className="my-4">
                <button className=" door-management-btn" onClick={handleDm}>
                  Door Management
                </button>
              </div>
          </div>*/}

        <div className="d-flex justify-content-evenly align-items-center w-100">
          <div className="left-line"></div>
          <h6 className="or">OR</h6>
          <div className="right-line"></div>
        </div>

        {/* Social Media Login */}
        <div className="container">
          <div className="d-flex justify-content-center align-items-center mt-4">
            <div className="w-100" style={{ maxWidth: '400px' }}>
              {/* Log in with Google */}
              <button className="d-flex align-items-center justify-content-center sign-in-options mb-3 w-100">
                {loginUrlGoogle ? (
                  <div className="d-flex align-items-center gap-2">
                    <Link to={loginUrlGoogle} className="d-flex align-items-center text-decoration-none">
                      <FcGoogle size={24} />
                      <span className="ms-2">Continue with Google</span>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}
              </button>

              {/* Log in with Facebook */}
              {/* <button className="d-flex align-items-center justify-content-center sign-in-options mb-3 w-100">
                {loginUrlGoogle ? (
                  <div className="d-flex align-items-center gap-2">
                    <Link to={"#"} className="d-flex align-items-center text-decoration-none" style={{ cursor: "default" }}>
                      <FaFacebook size={24} style={{ color: "#1877f2" }} />
                      <span className="ms-2">Continue with Facebook</span>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}
              </button> */}

              {/* Log in with Apple */}
              {/* <button className="d-flex align-items-center justify-content-center sign-in-options w-100">
                {loginUrlGoogle ? (
                  <div className="d-flex align-items-center gap-2">
                    <Link to={"#"} className="d-flex align-items-center text-decoration-none" style={{ cursor: "default" }}>
                      <FaApple size={24} style={{ color: "#000000" }} />
                      <span className="ms-2">Continue with Apple</span>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
