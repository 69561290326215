import React, { useEffect, useRef, useState } from "react";
import Banner from "./Banner";
// import Organiser from "./Organiser";
import "../EventDetails/css/HomePage.css"
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateEventId } from "../../redux/user";
import { devApi } from "../../utils/constants";
import axios from "axios";
import _ from "lodash";

import { IoMdArrowRoundBack } from "react-icons/io";
import { setEventDetails } from "../../redux/ticket";

export default function HomePage({ data }) {
  const [eventData, setEventData] = useState(null);
  // const [isSaved, setIsSaved] = useState(false);
  const { inputValue } = useParams();
  const ticketSummaryRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateEventId(inputValue));
    const fetchEventDetails = async () => {
      try {
        await axios
          .get(`${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`)
          .then((response) => {
            setEventData(response.data);
            // console.log("Event Data from the HomePage:", response.data);
            dispatch(setEventDetails(response.data));
            loadInitialState(response.data);
          });
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };
    const loadInitialState = async (data) => {
    };

    fetchEventDetails();
  }, [dispatch, inputValue]);

  if (!eventData) {
    return (
      <div className="new-bg d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  // const handleSaveEvent = () => {
  //   setIsSaved((prev) => !prev);
  //   if (!isSaved) {
  //     alert("Your Event is Saved Successfully...");
  //   } else {
  //     alert("Your Event is Unsaved...");
  //   }
  // };

  // const handleShareEvent = () => {
  //   const shareUrl = `${devApi}/api/e/fetchEventDetails?event_id=${inputValue}`;
  //   <WhatsappShareButton url={shareUrl}>
  //     <WhatsappIcon size={20} round={true} />
  //   </WhatsappShareButton>
  // };

  return (
    <>
      <div className="new-bg">
        <div className="container pt-4 mb-3 d-flex justify-content-between align-items-center">
          {/* Back Button */}
          <div className="d-flex align-items first-line-links-center back-to-event">
            <button aria-label="Back to Events" className="d-flex align-items-center gap-2 border-0 bg-transparent" onClick={() => { navigate("/") }}>
              <IoMdArrowRoundBack size={22} className="first-line-icon" />
              <span className="d-flex back-to-event ">Back to Events</span>
            </button>
          </div>
        </div>

        {/* Event Content */}
        <div className="container p-0">
          <div className="row m-0 p-0">
            <div className="col-12 col-lg-12 mb-4 p-0 gap-3">
              {eventData.data ? (
                <>
                  <Banner ticketOrder={ticketSummaryRef} data={eventData} />
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                  <div className="col-lg-6">
                    <h1 className="text-center">No data Found</h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
