import React, { useState } from "react";
import "./AdminLayout.css";
// import SideBar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
import Header from "./Header/Header";
export default function AdminLayout() {
  const [opensidebar, setOpensidebar] = useState(false);
  return (
    <div>
      <div className=" d-flex">
        <div className="app-header fixed-top">
          <Header />
          {/* // <SideBar opensidebar={opensidebar} setOpensidebar={setOpensidebar}/>*/}
        </div>
        <Content opensidebar={opensidebar} setOpensidebar={setOpensidebar} />
      </div>
    </div>
  );
}
