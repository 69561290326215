import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import "./css/VerifyEmail.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import newLogo from "../../Assets/newLogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { signupVerify } from "../../redux/user";
import { devApi } from "../../utils/constants";
import { FiMail } from "react-icons/fi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { CiCircleCheck } from "react-icons/ci";

export default function VerifyEmail() {
  const signupDetails = useSelector((state) => state.user.signupDetails);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailField, setEmailField] = useState(true);
  const [otpsent, setOtpsent] = useState(true);
  const [newPasswordField, setNewPasswordField] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const { state } = useLocation();
  const [errors, setErrors] = useState({
    email: "",
    otpError: "",
  });

  useEffect(() => {
    if (state) {
      handleResendEmailOtp();
      dispatch(signupVerify({}));
    }
  }, []);

  useEffect(() => {
    let timerInterval = null;
    if (otpsent && seconds > 0) {
      timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval); // Stop the timer when seconds reach 0
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerInterval); // Cleanup the interval
  }, [otpsent, seconds]);

  const queryParams = new URLSearchParams(location.search);
  const fromPage = queryParams.get("from") || "";

  const handleNavigate = () => {
    if (fromPage && fromPage.startsWith("signup")) {
      navigate("/login");
    } else {
      navigate(-1);
    }
  };

  // OTP Verification
  const verifyOtp = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const body = {
        email: state ? state.email : signupDetails.data.email,
        password: state ? "" : signupDetails.raw_pass,
        client_id: state
          ? state.cust_account_id
          : signupDetails.data.cust_account_id,
        confirmation_code: otp,
      };

      const response = await axios.post(`${devApi}/api/validateOTP`, body, config);
      if (response.data.success) {
        setShowModal(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          otpError: "",
        }));
      } else {

        setErrors((prevErrors) => ({
          ...prevErrors,
          otpError: response.data.message || "Invalid OTP",
        }));
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otpError: error.response?.data?.message || "An unexpected error occurred",
      }));

      if (error.response?.status === 450) {
        toast.error("Session Expired, Please log-in again");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors.otpError) {
      toast.error(errors.otpError);
    }
  }, [errors.otpError]);

  // Handle Resend OTP
  const handleResendEmailOtp = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    };

    const body = {
      client_id: state
        ? state.cust_account_id
        : signupDetails.data.cust_account_id,
    };

    // Send OTP resend request
    await axios
      .post(`${devApi}/api/resentOTP`, body, config)
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data.message);
          toast.success(response.data.message);
          setOtpsent(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        if (error.response?.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };

  return (
    <div className="new-bg d-flex vh-100 justify-content-center align-items-center">
      <div className="container">
        <div className="logo d-flex justify-content-center align-items-center mb-3">
          <div className="mail-bg">
            <FiMail size={48} />
          </div>
        </div>
        <div className="text-center my-3">
          <h4 className="signup-title text-capitalize">Check Your Email</h4>
        </div>
        <div className="col-lg-6 col-md-12 col-ms-12 col-12 login-page mx-auto p-0">
          <p>
            <Link
              to="/signup"
              className="reset-email"
            >
              Click here to change you email address
            </Link>
          </p>
          <div className="text-center mb-4 verification-text">
            <div>We sent a verification link to{" "}</div>
            <strong style={{ fontWeight: "500 !important" }}>{state ? state.email : signupDetails.data.email}</strong>
          </div>
          <div className="otp-inputs">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </div>
          {errors.otpError && (
            <small className={"text-danger mt-4 fs-5"}>{errors.otpError}</small>
          )}
          {loading ? (
            <div className="container mt-3">
              <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="w-100" style={{ maxWidth: '400px' }}>
                  <button
                    className="btn w-100 get-started d-flex justify-content-center align-items-center"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="container mt-3">
              <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="w-100" style={{ maxWidth: '400px' }}>
                  <button
                    className={`btn w-100 get-started ${otp === "" ? "signup-btn-disable" : "signup-btn"
                      }`}
                    onClick={verifyOtp}
                  >
                    Verify Email
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-4 gap-2">
            <span className="receive-email">Didn't receive the email?{" "}</span>
            <span
              className={`${seconds === 0
                ? "resend-link"
                : "resend-link-inactive"
                } ${seconds > 0 ? "disabled-link" : ""}`}
              onClick={seconds === 0 ? handleResendEmailOtp : null}
            >
              Click to Resend
            </span>
            {seconds > 0 && (
              <span className="me-3 resend-link-inactive" style={{ color: "#121212" }}>
                {seconds}
              </span>
            )}
          </div>

          <div className="container p-3 d-flex justify-content-center align-items-center pt-4">
            <Link className="ms-2 back-to-event border-0 d-flex align-items-center gap-2" to={"/login"}>
              <IoMdArrowRoundBack size={22} className="first-line-icon" />
              Back to Login
            </Link>
          </div>
        </div>

      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        className="p-0"
      >
        <Modal.Body className="p-0">
          <div className="forgot-modal new-bg p-5 rounded">

            <div className="logo d-flex justify-content-center align-items-center mb-3">
              <div className="mail-bg">
                <CiCircleCheck size={48} />
              </div>
            </div>
            <h4 className="verified-email my-3">Email Verified</h4>
            <p className="verified-email-desc">
              Your email has been successfully verified. You are all set to get back in.
            </p>
            <div className="d-flex justify-content-center">
              <button className="border-0 get-started w-50 my-2 text-center text-decoration-none" onClick={handleNavigate}>
                Log in
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
}
// {
//     "success": true,
//     "message": "User created successfully",
//     "raw_pass": "Sumant@123",
//     "data": {
//         "first_name": "Sumant",
//         "last_name": "Test",
//         "email": "thakre.sumant@gmail.com",
//         "phone": null,
//         "country_code": null,
//         "cust_account_id": 40,
//         "is_parent": 1,
//         "is_registered": 1,
//         "confirm_code_exp": "2024-05-17T11:11:55.604364Z",
//         "confirmation_code": 4901,
//         "api_token": "ti0w1YNH38zaL14QNjMC3e9wcMAuWB3ChDvzNsuJ3pWrxB2idBjnAsjtjR1g",
//         "updated_at": "2024-05-17 11:10:55",
//         "created_at": "2024-05-17 11:10:55",
//         "id": 40
//     }
// }
