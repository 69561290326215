import React from 'react'
import './css/Notification.css'

const Notification = () => {
    return (
        <div className='new-bg'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 mb-3 mt-2">
                        <div className="notification-card">
                            <div className="notification-sender d-flex align-items-center justify-content-between mb-3">
                                <div className="left-side-title d-flex align-items-center" style={{ whiteSpace: "nowrap" }}>
                                    <div className="me-1">Sumant Thakre</div>
                                    <div className="d-flex align-items-center mx-1">•</div>
                                    <div className="notification-date ms-1">20 Apr 2025</div>
                                </div>
                                <div className="check-status">Unread</div>
                            </div>
                            <div className="notification-details my-3">
                                <div className="notification-title mb-1">Event Postponed</div>
                                <div className="notification-desc">
                                    Due to the current weather conditions we are postponing the event to next Saturday.
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-left gap-3 mt-3">
                                <button className="dismiss-notification border-0 bg-none">Dismiss</button>
                                <button className="read-notification border-0 bg-none">Read Full Message</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-12 mb-3 mt-2">
                        <div className="notification-card">
                            <div className="notification-sender d-flex align-items-center justify-content-between mb-3">
                                <div className="left-side-title d-flex align-items-center" style={{ whiteSpace: "nowrap" }}>
                                    <div className="me-1">Sumant Thakre</div>
                                    <div className="d-flex align-items-center mx-1">•</div>
                                    <div className="notification-date ms-1">20 Apr 2025</div>
                                </div>
                                <div className="check-status">Unread</div>
                            </div>
                            <div className="notification-details my-3">
                                <div className="notification-title mb-1">Event Postponed</div>
                                <div className="notification-desc">
                                    Due to the current weather conditions we are postponing the event to next Saturday.
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-left gap-3 mt-3">
                                <button className="dismiss-notification border-0 bg-none">Dismiss</button>
                                <button className="read-notification border-0 bg-none">Read Full Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification
