import React, { useState } from "react";
import { FiMail } from "react-icons/fi";
import { useSelector } from "react-redux";
import './css/Profile.css'
import { devApi } from "../../utils/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux'
import { updateUserData } from '../../redux/user';
// import validator from "validator";

export default function Profile() {
  const user = useSelector((state) => state.user.user);
  const [proceedLoading, setProceedLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const [updateData, setUpdateData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const regForEmail = RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/);

  const changeData = (e) => {
    let { name, value } = e.target;

    setUpdateData((prevData) => {
      const newData = { ...prevData, [name]: value };

      const isFormChanged =
        newData.first_name !== user.first_name ||
        newData.last_name !== user.last_name ||
        newData.email !== user.email;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.length === 0 ? `${name.replace("_", " ")} is required` : "",
      }));

      setIsButtonDisabled(!(isFormChanged && !Object.values(errors).some((err) => err)));

      return newData;
    });
  };
  
  const checkError = () => {
    let dataIsValid = true;
    let newErrors = { ...errors };

    if (!updateData.first_name.trim()) {
      newErrors.first_name = "First Name is required";
      dataIsValid = false;
    }

    if (!updateData.last_name.trim()) {
      newErrors.last_name = "Last Name is required";
      dataIsValid = false;
    }

    if (!updateData.email || !regForEmail.test(updateData.email)) {
      newErrors.email = "Invalid email";
      dataIsValid = false;
    }

    if (JSON.stringify(newErrors) !== JSON.stringify(errors)) {
      setErrors(newErrors);
    }

    setIsButtonDisabled(!dataIsValid);
    return dataIsValid;
  };

  const handleReset = (e) => {
    e.preventDefault();
    setUpdateData({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email
    });
    setIsButtonDisabled(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!checkError()) return;

    setProceedLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const requestBody = {
      first_name: updateData.first_name,
      last_name: updateData.last_name,
    };

    try {
      const response = await axios.post(`${devApi}/api/updateClient`, requestBody, { headers });

      if (response.data.success) {
        toast.success("Profile updated successfully!");
        // console.log("Updated First Name:", updateData.first_name);
        // console.log("Updated Last Name:", updateData.last_name);

        dispatch(updateUserData({
          first_name: updateData.first_name,
          last_name: updateData.last_name,
          email: updateData.email,
        }));
        setIsButtonDisabled(false);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, api: response.data.message }));
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    } finally {
      setProceedLoading(false);
    }
  };

  return (
    <div className="container pt-3 pt-sm-3 pt-md-3 pt-lg-0">
      {/*<div className="d-flex align-items-center">
        <button className="btn" onClick={() => history(-1)}>
          <div className="d-inline-flex">
            <MdKeyboardArrowLeft />
            <h2 className="back-btn ms-2">Go Back</h2>
          </div>
        </button>
  </div>*/}
      <div className="row d-flex">
        {/* Left Section */}
        <div className="col-12 col-lg-4 mb-0 mt-0 mt-sm-3 mt-md-3 mt-lg-0">
          <div className="personal-info">Personal Info</div>
          <p className="personal-info-desc">Update your personal details.</p>
        </div>

        {/* Right Section */}
        <div className="col-12 col-lg-8">
          <div className="profile-card p-4">
            <div className="card-body">
              <form>
                {/* First Name and Last Name */}
                <div className="row g-3 mb-3">
                  <div className="col-12 col-md-6">
                    <label htmlFor="firstName" className="form-label-profile mb-2">
                      First Name
                    </label>
                    <span className="required-sign">*</span>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="first_name"
                      // value={user.first_name || "-"}
                      value={updateData.first_name}
                      onChange={(e) => {
                        changeData(e)
                      }}
                    />
                    <small className="text-danger ml-2">{errors.first_name}</small>
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="lastName" className="form-label-profile mb-2">
                      Last Name
                    </label>
                    <span className="required-sign">*</span>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="last_name"
                      // value={user.last_name || "-"}
                      value={updateData.last_name}
                      onChange={(e) => {
                        changeData(e)
                      }}
                    />
                    <small className="text-danger ml-2">{errors.last_name}</small>

                  </div>
                </div>

                {/* Email Address */}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label-profile mb-2">
                    Email Address
                  </label>
                  <span className="required-sign">*</span>
                  <div className="input-group">
                    <button
                      className="input-group-text"
                      style={{ color: "#667085", background: "#e9ecef" }}
                      disabled
                    >
                      <FiMail size={16} />
                    </button>
                    <input
                      type="email"
                      className="form-control no-focus-shift"
                      id="email"
                      name="email"
                      disabled
                      // value={user.email || "-"}
                      value={updateData.email}
                      onChange={(e) => {
                        changeData(e)
                      }}
                    />
                    <small className="text-danger ml-2">{errors.email}</small>

                  </div>
                </div>

                {/* Buttons */}
                <div className="d-flex gap-2 justify-content-end">
                  <button aria-label="Cancel" className="terms-btn w-auto my-1"
                    disabled
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button
                    aria-label="Save"
                    className="get-started w-auto my-1"
                    disabled={isButtonDisabled}
                    style={{
                      opacity: isButtonDisabled ? 0.8 : 1,
                    }}
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
