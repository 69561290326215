import React from "react";
import "./css/LoginDM.css";
import newLogo from "../../Assets/newLogo.svg";
import { Link } from "react-router-dom";

export default function InvalidDmLink() {
  return (
    <div className="root-container d-flex align-items-center justify-content-center mt-10">
      <div className="logindm-page">
        <Link to="/">
          <img src={newLogo} alt="" />
        </Link>
        <p className="text-center p-2 mt-4">
          <small className={"text-danger ml-2"}>
            This link is not valid. Contact your organizer.
          </small>
        </p>
      </div>
    </div>
  );
}
