import React from "react";
import "./Content.css";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ticket1 from "../../../Assets/ticket1.png";

export default function Content({ opensidebar, setOpensidebar }) {
  return (
    <div className={!opensidebar ? "app-wrapper" : "app-wrapper1"}>
      <div className="app-container">
        <div className="row justify-content-between">
          <h3 className="dash-head">Dashboard</h3>
          <div className="d-flex flex-row justify-content-equal">
            <div className="col-lg-4 p-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row d-flex justify-content-between">
                    <div
                      className={
                        !opensidebar ? "ticket-count" : "ticket-count1"
                      }
                    >
                      <ConfirmationNumberOutlinedIcon className="img-fluid" />
                      <div className="ticket-count-data">
                        <h6>0 Tickets</h6>
                        <p>Tickets Sold Today</p>
                      </div>
                    </div>
                    <div className="ticket-count">
                      <img src={ticket1} alt="" className="img-fluid" />
                      <div className="ticket-count-data">
                        <h6>0 Requests</h6>
                        <p>Refund Requests</p>
                      </div>
                    </div>
                    <div className="col-lg-12 ticket-status mt-3">
                      <h5>Ticket Status</h5>
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <h6 className="noevent">
                          Create an Event to View Ticket Status
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 p-3">
              <div className="d-flex flex-column ">
                {" "}
                <div className=" col-lg-12 social-media">
                  <h5>Social Media Reach</h5>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <h6 className="noevent">
                      Create an Event to view Social Media Reach
                    </h6>
                  </div>
                </div>
                <div className="col-lg-12 social-media mt-3">
                  <h5>Social Media Reach</h5>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <h6 className="noevent">
                      Create an Event to view Social Media Reach
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
