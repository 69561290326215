import { useEffect, useState } from "react";
import "./Render.css";
export default function BackToTop() {
  const [visible, setVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else if (window.scrollY <= 300) {
        setVisible(false);
      }
    });
  }, []);

  return (
    <>
      {visible ? (
        <>
          {" "}
          <i
            className="bi bi-arrow-up-circle button-up mb-3 mb-sm-5rem"
            onClick={scrollToTop}
          ></i>
        </>
      ) : (
        ""
      )}
    </>
  );
}
