import React from "react";
import "./css/Footer.css";
import footerLogo from "../../Assets/footerLogo.svg";
import ratingBadge from "../../Assets/Ratings badge.svg"
// import { FaInstagram, FaXTwitter, FaLinkedin, FaFacebook, FaLink } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { FiMail } from "react-icons/fi";

export default function FooterDetails() {
  const location = useLocation();
  const displayPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/verifyemail" ||
    location.pathname === "/doormanagement" ||
    location.pathname === "/scanner" ||
    location.pathname === "/dashboard" ||
    location.pathname === "/organizersignup" ||
    location.pathname === "/organizerlogin" ||
    location.pathname === "/devicescanner" ||
    location.pathname === "/camerascanner" ||
    location.pathname === "/doormanagerdetails" ||
    location.pathname === "/invalidlink";
  return (
    <>
      {!displayPage && (
        <div className="footer">
          <div className="container">
            <div className="row justify-content-between footer-box">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <Link to="/">
                  <img
                    src={footerLogo}
                    alt="Company Logo"
                    loading="lazy"
                    className="footer-img img-fluid"
                  />
                </Link>
                <div className="footer-content my-5">
                  Explore exciting events and unique experiences happening near you!
                </div>
                {/* <div className="ratings-img my-4 mx-2">
                  <img src={ratingBadge} alt="ratings" />
                </div> */}
                {/* <div className="contact-us footer-link mt-5 d-flex flex-column gap-2 ms-3" style={{ color: "#f49f9f" }}>
                  <strong>Contact Us:</strong>
                  <div className="d-flex gap-2">
                    <FiMail
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }} />
                    <Link to="mailto:info@eventgem.com">
                      info@eventgem.com</Link>
                  </div>
                  <div className="d-flex gap-2">
                    <FaInstagram
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                    <Link
                      to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn"
                      target="_blank"
                    >
                      Instagram
                    </Link>
                  </div>
                </div> */}
              </div>
              <div className="mt-3 col-lg-2 col-md-3 col-sm-6 col-6 footer-link">
                <h1 className="footer-heading">Company</h1>
                <Link to="/aboutus">About Us</Link>
                <Link to={"https://dev.organizer.eventgem.com/login"}>Organizer Portal</Link>
                {/* <Link to="/contactUs">Contact</Link> */}
              </div>
              <div className="mt-3 col-lg-2 col-md-3 col-sm-6 col-6 footer-link">
                <h1>Resources</h1>
                <Link to="/">Events</Link>
                <Link to="/faq">FAQ</Link>
              </div>

              {/* <div className="mt-3 col-lg-2 col-md-3 col-sm-6 col-6 footer-link">
                <h1>Social</h1>
                <Link to="twitter.com">Twitter</Link>
                <Link to="linkedin.com">Linkedin</Link>
                <Link to="facebook.com">Facebook</Link>
                <Link
                  to="mailto:info@eventgem.com"
                  className="align-items-center"
                >
                  <FiMail
                    style={{ width: "28px", height: "28px", color: "#024DDF" }}
                  />
                  E-Mail
                </Link>
              </div> */}

              <div className="mt-3 col-lg-2 col-md-3 col-sm-6 col-6 footer-link">
                <h1>Legal</h1>
                <Link to="/termsandconditions">Terms</Link>
                <Link to="/privacypolicy">Privacy</Link>
                <Link to="/cookiepolicy">Cookies</Link>
              </div>

              <div className="mt-3 col-lg-2 col-md-3 col-sm-6 col-6 footer-link">
                <h1>Contact Us</h1>
                <div className="d-flex align-items-center gap-2 mb-2 overflow-hidden">
                  <FiMail
                    size={20}
                    className="flex-shrink-0"
                    style={{
                      color: "#FFFFFF",
                    }} />
                  <Link to="mailto:info@eventgem.com" className="p-0">
                    info@eventgem.com</Link>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <FaInstagram
                    size={20}
                    style={{
                      color: "#FFFFFF",
                    }}
                  />
                  <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn"
                    target="_blank" className="p-0"
                  >
                    Instagram
                  </Link>
                </div>
              </div>
              <div className="artist-follow-icon d-flex align-items-center justify-content-between">
                <h2 className="footerarr">
                  © 2025 Eventgem. All rights reserved.
                </h2>
                <div className="social-media-icons d-flex align-items-center">
                  {/* Email */}
                  {/* <Link
                    to="mailto:info@eventgem.com"
                    target="_blank"
                  >
                    <FiMail
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                  </Link> */}
                  {/* Instagram */}
                  {/* <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn"
                    target="_blank"
                  >
                    <FaInstagram
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                  </Link> */}
                  {/* Twitter */}
                  {/* <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn" // Needs to be update
                    target="_blank"
                  >
                    <FaXTwitter
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                  </Link> */}
                  {/* Linkedin */}
                  {/* <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn" // Needs to be update
                    target="_blank"
                  >
                    <FaLinkedin
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                  </Link> */}
                  {/* Facebook */}
                  {/* <Link
                    to="https://www.instagram.com/eventgemtickets?igsh=Ymc2ZTd2NnUyOTJn" // Needs to be update
                    target="_blank"
                  >
                    <FaFacebook
                      size={25}
                      style={{
                        color: "#F49F9F",
                      }}
                    />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
