import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import "bootstrap";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "bootstrap-icons/font/bootstrap-icons.css";
import ReduxContainer from "./ReduxContainer.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<ReduxContainer />);
