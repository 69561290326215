import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchHandler = ({ searchEvent }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const prevSearchEvent = useRef(null);

    useEffect(() => {
        if (searchEvent && searchEvent !== prevSearchEvent.current && location.pathname !== "/checkout") {
            navigate("/");
        }

        prevSearchEvent.current = searchEvent;
    }, [searchEvent, navigate, location.pathname]);

    return null;
};

export default SearchHandler;
