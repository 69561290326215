import React from "react";
import './PageNotFound.css';
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
export default function PageNotFound() {
  return (
    <div className="new-bg">
      <div className="container mt-5 mt-sm-5 mt-md-0 mt-lg-0 d-flex align-items-start align-items-md-center align-items-lg-center" style={{ height: "90vh" }}>
        <div className="row">
          <div className="error-404">404 error</div>
          <div className="page-not-found mt-2">
            We can’t find that page
          </div>
          <div className="page-not-desc mt-4">
            Sorry, the page you are looking for doesn't exist or has been moved.
          </div>
          <div className="d-flex flex-column flex-lg-row gap-3 mt-4 col-12 col-lg-2 w-35">
            <Link to={-1} className="d-flex gap-2 align-items-center justify-content-center go-back w-100">
              <IoMdArrowRoundBack size={22} className="first-line-icon" />
              <span>Go Back</span>
            </Link>
            <Link to={"/"} className="d-flex gap-2 align-items-center justify-content-center go-back go-home w-100">
              <span>Take me home</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
