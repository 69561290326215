import React, { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import newLogo from "../../Assets/newLogo.svg";

import "./css/ForgotPassword.css";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
import { CiCircleCheck } from "react-icons/ci";
import { RiLock2Line } from "react-icons/ri";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [custId, setCustId] = useState();
  const [user, setUser] = useState();
  const Ref = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typing, setTyping] = useState(false);
  const [strength, setStrength] = useState();
  const [passScore, setPassScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const custId = urlParams.get("cust");
    setCustId(custId);
    const getUserData = async () => {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const body = {
        cust: custId,
      };
      await axios
        .post(`${devApi}/api/resetPasswordCustData`, body, config)
        .then((response) => {
          if (response.data.success) {
            setUser(response.data.user);
          } else setUser("Not Found");
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    };
    getUserData();
  }, []);
  const handleInputChange = (event) => {
    let inputError = {
      email: "",
      password: "",
      confirm_password: "",
    };
    setErrors(inputError);
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };

  const validateFormInput = async (event) => {
    event.preventDefault();
    let inputError = {
      email: "",
      password: "",
      confirm_password: "",
    };
    if (!formFields.password && !formFields.confirm_password) {
      setErrors({
        ...inputError,
        password: "New password is required",
        confirm_password: "Confirm password should match new password",
      });
      return;
    }
    if (formFields.password.length < 8) {
      setErrors({
        ...inputError,
        password: "Password should contain minimun 8 characters",
      });
      return;
    }
    if (formFields.password !== formFields.confirm_password) {
      setErrors({
        ...inputError,
        confirm_password: "Confirm password should match new password",
      });
      return;
    }
    if (!errors.password && !errors.confirm_password) {
      const body = {
        cust: custId,
        password: formFields.password,
        confirm_password: formFields.confirm_password,
      };
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      await axios
        .post(`${devApi}/api/confirmNewPassword`, body, config)
        .then((response) => {
          if (response.data.success) {
            setShowModal(true);
          } else {
            setErrors({
              ...errors,
              confirmPassword:
                "There was some error while settting your password",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/login");
          }
        });
    }
  };

  //   useEffect(() => {
  //     if (otpsent === true && seconds > 0) {
  //       const timerInterval = setInterval(() => {
  //         setSeconds((prevSeconds) => {
  //           if (prevSeconds === 1) {
  //             clearInterval(timerInterval);
  //           }
  //           return prevSeconds - 1;
  //         });
  //       }, 1000);

  //       return () => clearInterval(timerInterval);
  //     }
  //   }, [otpsent, seconds]);
  if (!user) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <div className="new-bg d-flex vh-100 justify-content-center align-items-center">
      <div className="container">
        <div className="logo d-flex justify-content-center align-items-center mb-3">
          <div className="mail-bg">
            <RiLock2Line size={48} />
          </div>
        </div>

        {user !== "Not Found" ? (
          <div className="container d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="text-center my-3">
                <h4 className="signup-title">Set New Password</h4>
                <p className="signup-desc">
                  Your new password must be different from previously used passwords.
                </p>
              </div>
              <div className="form-container">
                {/* Email */}
                <div className="form-group mb-3">
                  <label htmlFor="email" className="signup-label mb-1">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control signup-input-field"
                    placeholder="Enter Email"
                    name="email"
                    id="email"
                    style={{ backgroundColor: "#F3F3F3" }}
                    value={user ? user.email : ""}
                    disabled
                    autoComplete="off"
                  />
                </div>

                {/* Password */}
                <div className="form-group mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <label
                      htmlFor="password"
                      className="signup-label mb-1 d-flex justify-content-between"
                    >
                      <span>Password</span>
                    </label>
                    <div className="ms-2">{strength}</div>
                  </div>
                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control signup-input-field pe-5"
                      placeholder="Enter Password"
                      name="password"
                      id="password"
                      onChange={(event) => {
                        handleInputChange(event);
                        setStrength(evaluatePasswordStrength(event.target.value));
                      }}
                      onFocus={() => setTyping(true)}
                      onBlur={() => setTyping(false)}
                      value={formFields.password}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  {typing && passScore !== 5 && (
                    <small className="text-primary ms-2">
                      Use 8 or more characters with a mix of letters, numbers & symbols
                    </small>
                  )}
                  <small className="text-danger ms-2">{errors.password}</small>
                </div>

                {/* Confirm-Password */}
                <div className="form-group mb-2">
                  <label
                    htmlFor="confirm_password"
                    className="signup-label mb-1 d-flex justify-content-between"
                  >
                    <span>Re-Enter New Password</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control signup-input-field pe-5"
                      placeholder="Re-Enter Password"
                      name="confirm_password"
                      id="confirm_password"
                      onChange={handleInputChange}
                      value={formFields.confirm_password}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  <small className="text-danger ms-2">{errors.confirm_password}</small>
                </div>

              </div>
              {loading === true ? (
                <div className="d-flex justify-content-center align-items-center">
                  <button aria-label="Login" className="login-btn" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm get-started"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              ) : (
                <button
                  type="submit"
                  className={
                    formFields.password === "" || formFields.confirmPassword === ""
                      ? "signup-btn-disable mt-3 w-100 get-started"
                      : "signup-btn mt-3 w-100"
                  }
                  onClick={validateFormInput}
                >
                  Reset Password
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="container d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-10 col-12 login-page">
              <div className="text-center">
                <h1>User Not Found</h1>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="p-0"
        size="lg"
      >
        <Modal.Body>
          <div className="forgot-modal new-bg p-5 rounded">
            <div className="logo d-flex justify-content-center align-items-center mb-3">
              <div className="mail-bg">
                <CiCircleCheck size={48} />
              </div>
            </div>
            <h4 className="verified-email my-3">Password Reset</h4>
            <div className="d-flex justify-content-center mt-4">
              <Link className="get-started w-50 my-2 text-center text-decoration-none" to="/login">
                Continue
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
