import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="new-bg text-justify">
      <div className="container">
        <button aria-label="Back to events" className="ms-2 back-to-event border-0 d-flex align-items-center gap-2 py-4 pb-2" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={22} className="first-line-icon" />
          Back to Events
        </button>
        <div className="row d-flex align-items-center justify-content-center p-4">
          <p className="text-center terms-title">
            <strong>
              <span data-contrast="auto">PRIVACY POLICY</span>
              {/* <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span> */}
            </strong>
          </p>
          <p className="text-center terms-title">
            <strong>
              <span data-contrast="auto">Last updated April 08, 2024</span>
              <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
            </strong>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This privacy notice for Good Karma Concept LLC doing business as eventgem.com also referred to as Eventgem ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Visit our website, or any website of ours that links to this privacy notice
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Download and use our mobile application, or any other application of ours that links to this privacy notice
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Engage with us in other related ways, including any sales, marketing, or events
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">SUMMARY OF KEY POINTS</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our{" "}
            </span>
            <a href="#table-contents" className="custom-link-terms">
              <span data-contrast="none">table of contents</span>
            </a>
            <span data-contrast="auto">
              {" "}
              below to find the section you are looking for.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Do we process any sensitive personal information?
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We do not process sensitive personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Do we receive any information from third parties?
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We do not receive any information from third parties.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                How do we process your information?
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                In what situations and with which types of parties do we share personal information?
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may share information in specific situations and with specific categories of third parties. Learn more about when and with whom we share your personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                How do we keep your information safe?{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">What are your rights?</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                How do you exercise your rights?{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              The easiest way to exercise your rights is by visiting eventgem.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Want to learn more about what we do with any information we collect? Review the privacy notice in full.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
              &nbsp;
            </span>
          </p>
          <p id="table-contents">
            <span data-contrast="none">
              <span data-ccp-parastyle="heading 3">TABLE OF CONTENTS</span>
            </span>
            <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
              &nbsp;
            </span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol className="terms-list">
            <li>
              <a href="#collected-information">
                <span data-contrast="none">
                  {" "}
                  WHAT INFORMATION DO WE COLLECT?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#process-information">
                <span data-contrast="none">
                  {" "}
                  HOW DO WE PROCESS YOUR INFORMATION?
                </span>
              </a>
            </li>
            <li>
              <a href="#share-information">
                <span data-contrast="none">
                  {" "}
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
              </a>
            </li>
            <li>
              <a href="#third-party-websites">
                <span data-contrast="none">
                  {" "}
                  WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                </span>
              </a>
            </li>
            <li>
              <a href="#cookies-tracking">
                <span data-contrast="none">
                  {" "}
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
              </a>
            </li>
            <li>
              <a href="#social-logins">
                <span data-contrast="none">
                  {" "}
                  HOW DO WE HANDLE YOUR SOCIAL LOGINS?&nbsp;
                </span>
              </a>
              <div>
                <a href="#google-login">
                  <span data-contrast="none">
                    {" "}
                    6a. HOW DO WE HANDLE GOOGLE LOGIN?
                  </span>
                </a>
              </div>
            </li>
            <li>
              <a href="#data-retention">
                <span data-contrast="none">
                  {" "}
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
            </li>
            <li>
              <a href="#data-safety">
                <span data-contrast="none">
                  {" "}
                  HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#minors-information">
                <span data-contrast="none">
                  {" "}
                  DO WE COLLECT INFORMATION FROM MINORS?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#privacy-rights">
                <span data-contrast="none">
                  {" "}
                  WHAT ARE YOUR PRIVACY RIGHTS?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#do-not-track">
                <span data-contrast="none">
                  {" "}
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </a>
            </li>
            <li>
              <a href="#us-privacy-rights">
                <span data-contrast="none">
                  {" "}
                  DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
            </li>
            <li>
              <a href="#updates-notice">
                <span data-contrast="none">
                  {" "}
                  DO WE MAKE UPDATES TO THIS NOTICE?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#contact-about-notice">
                <span data-contrast="none">
                  {" "}
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&nbsp;
                </span>
              </a>
            </li>
            <li>
              <a href="#review-update-delete">
                <span data-contrast="none">
                  {" "}
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?&nbsp;
                </span>
              </a>
            </li>
          </ol>

          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol>
            <li id="collected-information">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  WHAT INFORMATION DO WE COLLECT?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Personal information you disclose to us
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                We collect personal information that you provide to us.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Personal Information Provided by You.{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* names</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* phone numbers</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* email addresses</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* mailing addresses</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* usernames</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* passwords</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* contact preferences</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* contact or authentication data</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* debit/credit card numbers</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Sensitive Information. </span>
            </strong>
            <span data-contrast="auto">
              We do not process sensitive information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Payment Data. </span>
            </strong>
            <span data-contrast="auto">
              We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here:{" "}
            </span>
            <a href="https://stripe.com/in/privacy" className="custom-link-terms">
              <span data-contrast="none">https://stripe.com/in/privacy</span>
            </a>
            <span data-contrast="auto">.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Social Media Login Data.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may provide you with the option to register with us using your existing social media account details, like your Facebook, Instagram, Twitter, or other social media accounts. If you choose to register in this way, we will collect the information described in the section called{" "}
            </span>
            <a href="#social-logins" className="custom-link-terms">
              <span data-contrast="none">
                "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
              </span>
            </a>
            <span data-contrast="auto"> Below.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Google Data.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may provide you with the option to register with us using your Google account. If you choose to register in this way, we will collect the information described in the section called{" "}
            </span>
            <a href="#google-login" className="custom-link-terms">
              <span data-contrast="none">
                "HOW DO WE HANDLE GOOGLE DATA"
              </span>
            </a>
            <span data-contrast="auto"> Below.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Application Data.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* </span>
            <em>
              <span data-contrast="auto">Push Notifications.</span>
            </em>
            <span data-contrast="auto">
              {" "}
              We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              TThis information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Information automatically collected
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>{" "}
            <em>
              <span data-contrast="auto">
                Some information &mdash; such as your Internet Protocol (IP)
                address and/or browser and device characteristics &mdash; is
                collected automatically when you visit our Services.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookie Notice.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              The information we collect includes:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* </span>
            <strong>
              <span data-contrast="auto">Log and Usage Data.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">*</span>
            <strong>
              <span data-contrast="auto"> Device Data. </span>
            </strong>
            <span data-contrast="auto">
              We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* </span>
            <strong>
              <span data-contrast="auto">Location Data.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="2">
            <li id="process-information">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW DO WE PROCESS YOUR INFORMATION?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To facilitate account creation and authentication and
                otherwise manage user accounts.{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To deliver and facilitate delivery of services to the user.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information to provide you with the requested
              service.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To respond to user inquiries/offer support to users.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To send administrative information to you.{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              We may process your information to send you details about our
              products and services, changes to our terms and policies, and
              other similar information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To fulfill and manage your orders.
              </span>
            </strong>
            <span data-contrast="auto">
              We may process your information to fulfill and manage your
              orders, payments, returns, and exchanges made through the
              Services.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To enable user-to-user communications.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information if you choose to use any of our
              offerings that allow for communication with another user.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* To request feedback.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information when necessary to request feedback
              and to contact you about your use of our Services.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To send you marketing and promotional communications.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see
            </span>
            <a href="#privacy-rights" className="custom-link-terms">
              <span data-contrast="none">
                {" "}
                "WHAT ARE YOUR PRIVACY RIGHTS? "
              </span>
            </a>
            <span data-contrast="auto"> below.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To deliver targeted advertising to you.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more. For more information see our Cookie Notice.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* To post testimonials.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We post testimonials on our Services that may contain personal
              information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* To protect our Services.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information as part of our efforts to keep our
              Services safe and secure, including fraud monitoring and
              prevention.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To evaluate and improve our Services, products, marketing, and
                your experience.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              promotional campaigns, and to evaluate and improve our Services,
              products, marketing, and your experience.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* To identify usage trends. </span>
            </strong>
            <span data-contrast="auto">
              We may process information about how you use our Services to
              better understand how they are being used so we can improve them.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To determine the effectiveness of our marketing and
                promotional campaigns.{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              We may process your information to better understand how to
              provide marketing and promotional campaigns that are most relevant
              to you.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                * To comply with our legal obligations.{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              We may process your information to comply with our legal
              obligations, respond to legal requests, and exercise, establish,
              or defend our legal rights.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="3">
            <li id="share-information">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short: </span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                We may share information in specific situations described in
                this section and/or with the following categories of third
                parties
              </span>
            </em>
            <span data-contrast="auto">.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Vendors, Consultants, and Other Third-Party Service Providers.
              </span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Payment Processors</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Sales &amp; Marketing Tools</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Social Networks</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Testing Tools</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * User Account Registration &amp; Authentication Services
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Website Hosting Service Providers
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Data Storage Service Providers</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Order Fulfillment Service Providers
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Communication &amp; Collaboration Tools
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Performance Monitoring Tools</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We also may need to share your personal information in the
              following situations:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* Business Transfers. </span>
            </strong>
            <span data-contrast="auto">
              We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* When we use Google Analytics. </span>
            </strong>
            <span data-contrast="auto">
              We may share your information with Google Analytics to track and
              analyze the use of the Services. To opt out of being tracked by
              Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout" className="custom-link-terms"> https://tools.google.com/dlpage/gaoptout</a>. For more information on
              the privacy practices of Google, please visit the Google Privacy
              &amp; Terms page (<a href="https://policies.google.com/privacy" className="custom-link-terms">https://policies.google.com/privacy</a>).
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* Affiliates.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">* Business Partners.</span>
            </strong>
            <span data-contrast="auto">
              {" "}
              We may share your information with our business partners to offer you certain products, services, or promotions.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="4">
            <li id="third-party-websites">
              <p>
                <span data-contrast="none">
                  <span data-ccp-parastyle="heading 3">
                    WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                  </span>
                </span>
                <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
                  &nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              The Services, including our offer wall, may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              them directly to respond to your questions.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="5">
            <li id="cookies-tracking">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  DO WE USE COOKIES AND OTHER{" "}
                </span>
                <span data-ccp-parastyle="heading 3">
                  TRACKING TECHNOLOGIES?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short: </span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                We may use cookies and other tracking technologies to collect and store your information.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Our Cookie Notice provides specific information about how we use such technologies and how you can refuse certain cookies.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="6">
            <li id="social-logins">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook, Instagram or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <p data-contrast="auto">Facebook Data Deletion Instructions </p>
            </strong>
            <p>
              <span data-contrast="auto">
                {" "}
                Eventgem does not process sensitive personal information.
              </span>
            </p>
            <p>
              <span>We use the personal information collected only in the context of your interactions with Eventgem and its Services.</span>
            </p>
            <p>
              <span>The Facebook policy mandates us to provide a User Data Deletion Callback URL or Data Deletion Instructions URL. </span>
            </p>
            <p>
              <span>
                If you would like to delete your data and activity for the Eventgem platform, kindly follow the instructions below:
                <ol>
                  <li>Go to your Facebook account's “Settings & Privacy” section.</li>
                  <li>Click ” Setting “. </li>
                  <li>Then, go to ” Apps and Websites,” and you will see the list of applications integrated with your account. </li>
                  <li>Select the option box for Eventgem. </li>
                  <li>Click the” Remove” button.</li>
                </ol>
                For any further help, please contact our support at <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>.
              </span>
            </p>
          </p>
          <li id="google-login" type="none">6a. HOW DO WE HANDLE GOOGLE LOGIN DATA?</li>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                If you choose to register or log in to our Services using a third-party single sign-on (SSO) service like Google, we may have access to certain information about you.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span>
              Our Services offer you the ability to register and log in using your third-party single sign-on (SSO) service like Google.Where you choose to do this, we will receive certain profile information such as your name and your email address to pre-populate our sign-up form.
            </span>
          </p>
          <p>
            <span>
              We may collect Personal Information from these third-party APIs such as Google to the extent you authorize us to do so, and our collection, use, and disclosure of that Personal Information will be governed by this privacy policy. In addition, if you authorize us to do so, we may grant third parties' access to some or all (depending on the permission you give) of your Personal Information to third parties through our own website for use in connection with their services. You have the ability to control what Personal Information you share with these third parties through our website, but please note that any Personal Information you share with third parties through our website will be governed by their privacy policies and we do not assume any responsibility for such third party's use of your Personal Information once shared through our website.
            </span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="7">
            <li id="data-retention">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
              &nbsp;
            </span>
          </p>
          <ol start="8">
            <li id="data-safety">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                We aim to protect your personal information through a system of organizational and technical security measures.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
              &nbsp;
            </span>
          </p>
          <ol start="9">
            <li id="minors-information">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>{" "}
            <em>
              <span data-contrast="auto">
                We do not knowingly collect data from or market to children under 18 years of age.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18, please contact us at <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"134245418":true,"134245529":true,"335551550":6,"335551620":6,"335559738":320,"335559739":80}'>
              &nbsp;
            </span>
          </p>
          <ol start="10">
            <li id="privacy-rights">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:&nbsp;</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                You may review, change, or terminate your account at any time.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section
            </span>
            <a href="#contact-about-notice" className="custom-link-terms">
              <span data-contrast="none">
                {" "}
                "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
              </span>
            </a>
            <span data-contrast="auto"> below.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Opting out of marketing and promotional communications:{" "}
              </span>
            </strong>
            <span data-contrast="auto">
              You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section{" "}
            </span>
            <a href="#contact-about-notice" className="custom-link-terms">
              <span data-contrast="none">
                "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"{" "}
              </span>
            </a>
            <span data-contrast="auto">below.</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Account Information</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If you would at any time like to review or change the information in your account or terminate your account, you can:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Log in to your account settings and update your user account.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. For further information, please see our Cookie Notice.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="11">
            <li id="do-not-track">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="12">
            <li id="us-privacy-rights">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short:</span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                {" "}
                If you are a resident of California, Colorado, Connecticut, Utah or Virginia, you are granted specific rights regarding access to your personal information.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                What categories of personal information do we collect?
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: 8 }}><strong>Category</strong></th>
                <th style={{ border: '1px solid black', padding: 8 }}><strong>Description</strong></th>
                <th style={{ border: '1px solid black', padding: 8 }}><strong>Examples Collected</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>A. Identifiers</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>B. Personal information as defined in the California Customer Records statute</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Name, contact information, education, employment, employment history, and financial information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>YES</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>C. Protected classification characteristics under state or federal law</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Gender and date of birth</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>D. Commercial information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Transaction information, purchase history, financial details, and payment information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>E. Biometric information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Fingerprints and voiceprints</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>F. Internet or other similar network activity</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>G. Geolocation data</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Device location</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Images and audio, video or call recordings created in connection with our business activities</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>I. Professional or employment-related information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>J. Education Information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Student records and directory information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>K. Inferences drawn from collected personal information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                <td style={{ border: '1px solid black', padding: 8 }}>YES</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: 8 }}>L. Sensitive personal Information</td>
                <td style={{ border: '1px solid black', padding: 8 }}>N/A</td>
                <td style={{ border: '1px solid black', padding: 8 }}>NO</td>
              </tr>
            </tbody>
          </table>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We will use and retain the collected personal information as needed to provide the Services or for:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Category K - As long as the user has an account with us
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Receiving help through our customer support channels;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Participation in customer surveys or contests; and
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Facilitation in the delivery of our Services and to respond to your inquiries.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                How do we use and share your personal information?
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Learn about how we use your personal information in the section,{" "}
            </span>
            <a href="#process-information" className="custom-link-terms">
              <span data-contrast="none">
                "HOW DO WE PROCESS YOUR INFORMATION?"
              </span>
            </a>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We collect and share your personal information through:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Targeting cookies/Marketing cookies
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Social media cookies</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">* Beacons/Pixels/Tags</span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Social media plugins: We use social media features, such as a "Like" button, and widgets, such as a "Share" button, in our Services. Such features may process your Internet Protocol (IP) address and track which page you are visiting on our website. We may place a cookie to enable the feature to work correctly. If you are logged in on a certain social media platform and you interact with a widget or button belonging to that social media platform, this information may be recorded to your profile of such social media platform. To avoid this, you should log out from that social media platform before accessing or using the Services. Social media features and widgets may be hosted by a third party or hosted directly on our Services. Your interactions with these features are governed by the privacy notices of the companies that provide them. By clicking on one of these buttons, you agree to the use of this plugin and consequently the transfer of personal information to the corresponding social media service. We have no control over the essence and extent of these transmitted data or their additional processing.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Will your information be shared with anyone else?
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section,{" "}
            </span>
            <a href="#share-information" className="custom-link-terms">
              <span data-contrast="none">
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? "
              </span>
            </a>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We have not sold or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under{" "}
            </span>
            <a href="#share-information" className="custom-link-terms">
              <span data-contrast="none">
                "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? "
              </span>
            </a>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">California Residents</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto"><u style={{ textUnderlineOffset: "3px" }}>CCPA Privacy Notice</u></span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              TThe California Code of Regulations defines a "residents" as:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              (1) every individual who is in the State of California for other than a temporary or transitory purpose and
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              All other individuals are defined as "non-residents."
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                Your rights with respect to your personal data
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>Right to request deletion of the data &mdash; Request to delete</u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>Right to be informed &mdash; Request to know</u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Depending on the circumstances, you have a right to know:
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * whether we collect and use your personal information;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the categories of personal information that we collect;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the purposes for which the collected personal information is
              used;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * whether we sell or share personal information to third parties;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * the specific pieces of personal information we collected about
              you.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>
                  Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s Privacy Rights
                </u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We will not discriminate against you if you exercise your privacy rights.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>Right to Limit Use and Disclosure of Sensitive Personal Information </u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We do not process consumer's sensitive personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto"><u style={{ textUnderlineOffset: "3px" }}>Verification process</u></span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto"><u style={{ textUnderlineOffset: "3px" }}>Other privacy rights</u></span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * You may object to the processing of your personal information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              To exercise these rights, you can contact us by visiting <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Colorado Residents</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to be informed whether or not we are processing your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to access your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to correct inaccuracies in your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to request deletion of your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to obtain a copy of the personal data you previously shared with us
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              To submit a request to exercise these rights described above, please visit <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If we decline to take action regarding your request and you wish to appeal our decision, please email us at
              <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link> Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Connecticut Residents</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to be informed whether or not we are processing your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to access your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to correct inaccuracies in your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to request deletion of your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to obtain a copy of the personal data you previously shared with us
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              To submit a request to exercise these rights described above,
              please visit <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>. Within
              sixty (60) days of receipt of an appeal, we will inform you in
              writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Utah Residents</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to be informed whether or not we are processing your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to access your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to request deletion of your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to obtain a copy of the personal data you previously shared with us
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              To submit a request to exercise these rights described above,
              please visit<Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">Virginia Residents</span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Under the Virginia Consumer Data Protection Act (VCDPA):
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              "Sale of personal data" means the exchange of personal data for monetary consideration.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>Your rights with respect to your personal data </u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to be informed whether or not we are processing your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to access your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to correct inaccuracies in your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to request deletion of your personal data
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to obtain a copy of the personal data you previously shared with us
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              * Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto">
                <u style={{ textUnderlineOffset: "3px" }}>Exercise your rights provided under the Virginia VCDPA </u>
              </span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto"><u style={{ textUnderlineOffset: "3px" }}>Verification process</u></span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span data-contrast="auto"><u style={{ textUnderlineOffset: "3px" }}>Right to appeal&rsquo;</u></span>
            </strong>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <Link to="mailto:info@eventgem.com" className="custom-link-terms"> info@eventgem.com</Link>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="13">
            <li id="updates-notice">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <p>
            <strong>
              <em>
                <span data-contrast="auto">In Short: </span>
              </em>
            </strong>
            <em>
              <span data-contrast="auto">
                Yes, we will update this notice as necessary to stay compliant with relevant laws.
              </span>
            </em>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <ol start="14">
            <li id="contact-about-notice">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              If you have questions or comments about this notice, you may contact us at: <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>.
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <ol start="15">
            <li id="review-update-delete">
              <span data-contrast="none">
                <span data-ccp-parastyle="heading 3">
                  {" "}
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </span>
              </span>
            </li>
          </ol>
          <p>
            <span data-ccp-props="{}">&nbsp;</span>
          </p>
          <p>
            <span data-contrast="auto">
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: <Link to="mailto:info@eventgem.com" className="custom-link-terms">info@eventgem.com</Link>
            </span>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
          <p>
            <span data-ccp-props='{"335551550":6,"335551620":6}'>&nbsp;</span>
          </p>
        </div>
      </div>
    </div >
  );
}
