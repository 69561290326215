// ticketSlice.js
import { createSlice } from "@reduxjs/toolkit";

const loadStateFromStorage = () => {
  try {
    const serializedState = localStorage.getItem("ticketState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error("Error loading state from localStorage:", error);
    return undefined;
  }
};

const saveStateToStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("ticketState", serializedState);
  } catch (error) {
    console.error("Error saving state to localStorage:", error);
  }
};

const initialState = {
  ticketTypes: loadStateFromStorage()?.ticketTypes || [],
  ticketCart: {},
  eventDetails: {},
};

const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    initializeTicketTypes: (state, action) => {
      state.ticketTypes = action.payload;
      saveStateToStorage(state);
    },
    decreaseQuantity: (state, action) => {
      const { index } = action.payload;
      if (state.ticketTypes[index] && state.ticketTypes[index].quantity > 0) {
        state.ticketTypes[index].quantity -= 1;
        saveStateToStorage(state);
      }
    },

    increaseQuantity: (state, action) => {
      const { index } = action.payload;
      if (state.ticketTypes[index]) {
        state.ticketTypes[index].quantity += 1;
        saveStateToStorage(state);
      }
    },
    updateQuantity: (state, action) => {
      const { index, quantity } = action.payload;
      state.ticketTypes[index].quantity = quantity;
      saveStateToStorage(state);
    },
    updateTicketCart: (state, action) => {
      state.ticketCart = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
  },
});
export const getTotalCost = (state) => {
  return state.tickets.ticketTypes.reduce((total, ticketType) => {
    return ticketType.groupCount
      ? total +
      ticketType.quantity *
      ticketType.groupCount *
      (ticketType.price / ticketType.groupCount)
      : total + ticketType.quantity * ticketType.price;
  }, 0);
};
export const getTaxes = (state) => {
  const eventDetails = state.tickets.eventDetails || {};
  // const event = eventDetails.data?.event || {};
  // console.log("Event Details:", eventDetails);
  // console.log("Event:", event);
  // const processingFeeRate = parseFloat(eventDetails.cc_processing) || 0;
  const processingFeeRate = parseFloat(eventDetails.event.cc_processing) || 0;
  // const bookingFeeRate = parseFloat(eventDetails.organiser_fee_percentage) || 0;
  const bookingFeeRate = parseFloat(eventDetails.event.organiser_fee_percentage) || 0;

  const totalCost = getTotalCost(state);
  return {
    processingFee: totalCost * (processingFeeRate / 100),
    bookingFee: totalCost * (bookingFeeRate / 100),
  };
};

export const getTotalTickets = (state) => {
  return state.tickets.ticketTypes.reduce((total, ticketType) => {
    return total + ticketType.quantity;
  }, 0);
};

export const {
  initializeTicketTypes,
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
  updateTicketCart,
  setEventDetails,
} = ticketSlice.actions;
export default ticketSlice.reducer;