import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function OrgTermsAndConditions() {
  const navigate = useNavigate();
  return (
    <div className="new-bg">
      <div className="container text-justify">
        <button aria-label="Back to events" className="ms-2 back-to-event border-0 d-flex align-items-center gap-2 py-4 pb-2" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={22} className="first-line-icon" />
          Back to Events
        </button>
        <div className="row d-flex align-items-center justify-content-center p-4 text-justify">
          <p className="text-center terms-title">
            <strong>
              <span data-contrast="auto">ORGANIZER TERMS AND CONDITIONS</span>
            </strong>
          </p>
          <p className="text-center">
            <strong>
              <span data-contrast="auto">Last updated April 04, 2024</span>
            </strong>
          </p>
          <ol className="m-0">
            <p>
              <span>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                This agreement ("Agreement") is entered into by and between Good Karma Concept LLC operating eventgem.com ("Platform/Website"), an entertainment ticketing portal organized and existing under the laws of USA, and the undersigned Organizer ("Organizer"), collectively referred to as the "Parties" or individually as a "Party".
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                This Agreement sets forth the terms and conditions under which the Organizer may use the services for event management and ticket sales through eventgem.com ("Platform/Website ") also referred to as Eventgem.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                By creating an account and listing an event, the Organizer agrees to be bound by this Agreement, including all its terms and conditions.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <ol start="1" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Definitions</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none" className="ms-4">
                For purposes of this Agreement, the following terms are defined as follows:
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <ul>
              <li
                type="disc"
                data-font="Roboto"
                data-listid="1"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Roboto","469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
                data-aria-posinset="1"
                data-aria-level="1"
              >
                <span data-contrast="none">
                  "Event" means any scheduled activity, occasion, or gathering for which the Organizer uses the Platform to manage and sell tickets.
                </span>
                <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                  &nbsp;
                </span>
              </li>
              <li
                type="disc"
                data-font="Roboto"
                data-listid="1"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Roboto","469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
                data-aria-posinset="2"
                data-aria-level="1"
              >
                <span data-contrast="none">
                  "User" refers to any individual or entity who purchases tickets through the Platform or participates in an Event organized by the Organizer.
                </span>
                <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                  &nbsp;
                </span>
              </li>
              <li
                type="disc"
                data-font="Roboto"
                data-listid="1"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Roboto","469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
                data-aria-posinset="3"
                data-aria-level="1"
              >
                <span data-contrast="none">
                  "Content" includes all visual, written, or audible data uploaded by the Organizer to the Platform, including but not limited to descriptions, photographs, reviews, and promotional material.
                </span>
                <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                  &nbsp;
                </span>
              </li>
              <li
                type="disc"
                data-font="Roboto"
                data-listid="1"
                data-list-defn-props='{"335552541":1,"335559685":720,"335559991":360,"469769226":"Roboto","469769242":[8226],"469777803":"left","469777804":"●","469777815":"multilevel"}'
                data-aria-posinset="4"
                data-aria-level="1"
              >
                <span data-contrast="none">
                  "Services" encompasses the functionalities provided by the Platform, including but not limited to event listing, ticket sales processing, marketing tools, data analytics, and customer support.
                </span>
                <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                  &nbsp;
                </span>
              </li>
            </ul>
            <p>
              <span>&nbsp;</span>
            </p>
            <ol start="2" className="p-0 mb-2">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    Applicability of the general terms and conditions
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-4"
            >
              <span data-contrast="none">
                2.1 These general terms and conditions apply to all offers, proposals, discussions and quotations of Eventgem, order confirmations, Agreements, however named and all legal acts between Eventgem and the Organizer.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-4"
            >
              <span data-contrast="none">
                2.2 Eventgem expressly excludes the applicability of any general terms and conditions of the Organizer unless explicitly agreed in writing.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-4"
            >
              <span data-contrast="none">
                2.3 These general terms and conditions also apply to anyone employed by Eventgem, anyone engaged by Eventgem, and anyone for whose acts or omissions Eventgem is or may be liable.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-4"
            >
              <span data-contrast="none">
                2.4 Should any provision in these general terms and conditions be void or otherwise unenforceable, this shall not affect the validity of the remaining provisions of these general terms and conditions and/or the Agreement between Eventgem and the Organizer, and Eventgem and the Organizer shall consult to agree on a new provision to replace the void/annulled or unenforceable provision, with the parties observing as far as possible the purpose and intent of the void/annulled or unenforceable provision.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-4"
            >
              <span data-contrast="none">
                2.5 Eventgem is entitled to unilaterally amend these general terms and conditions. In the event of an amendment to the general terms and conditions, Eventgem shall notify the Organizer accordingly in writing. The Organizer agrees to these changes in advance so that the amended general terms and conditions will apply to all Services provided by Eventgem after notification of the amended general terms and conditions.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <ol start="3" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    The Services and Platform&nbsp;
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.1 Eventgem's Services include using the Platform to ensure that (e)Tickets for an event organized by the Organizer can be offered by Eventgem on behalf of the Organizer to Users through the Platform, as well as certain additional products.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.2 Eventgem shall provide the Organizer with access to the Platform. The Organizer shall be responsible for checking any and all information posted on the Platform, and Eventgem shall never be liable towards the Organizer for any errors in information posted on the Platform with respect to (e)Ticket(s) or an event organized by the Organizer.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.3 The (e)Ticket(s) are bought and sold via the Platform where the User acts as buyer and Eventgem as seller of the (e)Ticket(s), all in accordance with the General Terms and Conditions. Eventgem’s only obligation under the Agreement is enabling the User to purchase an (e)Ticket to the event of the Organizer. Eventgem shall in no way be liable to the User and/or the Organizer for any damages related to the event organized by the Organizer.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.4 The Organizer represents and warrants that all information and materials provided are true, complete, accurate and current and will promptly notify Eventgem if information or materials do not possess the aforementioned qualities. The Organizer guarantees the accuracy, completeness and reliability of the data and records made available to Eventgem, even if they are from third parties, and declares that they are lawfully in their possession.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.5 Eventgem will provide Organizers and Users with remote access to the Platform and Services via the Internet or other similar and applicable networks.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                3.6 Use of the Platform by the Organizer and Users must at all times be in accordance with the relevant regulations and procedures set forth by Eventgem, including these general terms and conditions.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":240,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="4" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    Account Registration and Management
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                4.1 <strong> Registration:  </strong>The Organizer must register for an account to access the Services. The Organizer agrees to provide true, accurate, current, and complete information as prompted by the registration form and to update this information as needed.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                4.2 <strong> Account Security: </strong> The Organizer is responsible for maintaining the confidentiality of their account and password and for restricting access to their computer. They agree to accept responsibility for all activities that occur under their account or password. Eventgem will not be held liable for any consequences arising from the Organizer sharing their password with others.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":160,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                4.3 <strong> Data Accuracy: </strong> The Organizer is obligated to ensure the accuracy and legality of all Content entered into the Platform and is solely responsible for the precision of all details related to their Events.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="5" className="p-0 mb-1">
              <span>
                <strong>
                  <li
                    data-ccp-border-bottom="0px none #e3e3e3"
                    data-ccp-padding-bottom="0px"
                    data-ccp-border-between="0px none #e3e3e3"
                    data-ccp-padding-between="0px"
                  >
                    <span data-contrast="none">
                      {" "}
                      Event Listings and Ticket Sales
                    </span>
                  </li>
                </strong>
              </span>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                The Organizer shall ensure that all Events listed on the Platform comply with applicable laws, regulations, and these Terms. The Organizer shall set forth clear terms regarding ticket sales, including but not limited to event dates, refund policies, and any age or other restrictions. The Organizer agrees to abide by fair pricing laws and not to engage in any form of price gouging or misleading advertising.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="6" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">Payment Methods</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                6.1 The Company shall collect the amount for the ticketing of the event ("Ticketing Sale Amount") through
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <ol>
              <p>
                <strong>
                  <span data-contrast="none">Stripe payment processing:</span>
                </strong>
                <span data-contrast="none">
                  {" "}Credit/debit card bookings are processed online, and the Ticketing Sales Amount is remitted to the Organizer's Bank Account.
                </span>
              </p>
            </ol>
            <p>
              <span data-contrast="none"><strong>6.2 Transaction Fees</strong></span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":300,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p
              className="ms-3">
              <span data-contrast="none">
                6.2.1 <strong>Fee Structure:</strong> Each transaction made through Eventgem will incur a transaction fee, which includes both the platform fee and payment processing costs. This fee is necessary to cover the operational costs associated with providing Eventgem services and processing payments securely.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":300,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p
              className="ms-3">
              <span data-contrast="none">
                6.2.2 <strong>Disclosure:</strong> The transaction fee will be automatically deducted from the transaction amount at the time of processing. The net amount, after deduction of the transaction fee, will be disbursed to the Organizer&rsquo;s designated account.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":300,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p
              className="ms-3">
              <span data-contrast="none">
                6.2.3 <strong>Responsibility:</strong> It is the responsibility of the Organizer to review and understand the fee structure prior to initiating transactions on Eventgem. Continued use of the platform after fee changes have taken effect constitutes acceptance of the new fees.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":300,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="9.333333333333334px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
              className="ms-3"
            >
              <span data-contrast="none">
                6.2.4 In the event the Bank details are not updated in the Dashboard, the Company representatives will attempt to contact the Organizer through their registered mail id and ask for valid Bank Details.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":160,"335559739":300,"335559740":276,"335572071":0,"335572072":2,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":7,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="7" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="9.333333333333334px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Door Management</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                7.1  <strong>Service Description:</strong> The Door Management Service is an optional add-on available to Organizers, which allows them to designate a Door Manager responsible for managing entry at event venues. This service includes, but is not limited to, guest list verification, ticket scanning, and on-site attendee support.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":160,"335559739":140,"335559740":276,"335572071":0,"335572072":2,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":7,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-contrast="none">
                7.2  <strong>Charges:</strong> The Door Management Service is not included in the standard platform fees and will incur an additional charge. The fees for utilizing the Door Management Service will be based on the scale and complexity of the event and will be detailed in the Organizer&rsquo;s service agreement. These fees are subject to change and will be updated in the service agreement and communicated to Organizers prior to any changes taking effect.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":160,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-contrast="none">
                7.3  <strong>Allocation of Door Manager:</strong> Organizers may allocate a Door Manager through Eventgem. Organizers are required to provide full details of the event, including date, location, and expected number of attendees, at least 14 days prior to the event date to ensure proper coordination.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":160,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-contrast="none">
                7.4  <strong>Acceptance of Terms:</strong> By opting in for the Door Management Service, the Organizer agrees to the terms outlined herein. Failure to comply with these terms may result in additional charges or discontinuation of services.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":160,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-contrast="none">
                7.5  <strong>Damage charges:</strong> In case of damage, loss, or theft of equipment, the Organizer must incur the cost of the damages.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":160,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="8" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    User Data and Privacy Compliance
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                8.1 The Event Organizer bears the ultimate responsibility for their Customer Data and its handling. They must ensure compliance with any State or Federal Data Protection Regulations to ensure the safe management of Customer data. Eventgem is not liable for the non-compliance of Event Organizers with Data Protection Regulations.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                Under applicable data protection laws and our Privacy Policy, all Customer personal data generated in connection to an event shall be owned by the Event Organizer and/or their Organization (the Data Controller). Subject to any relevant customer consent, the Event Organizer can use such Customer data as they see fit. Eventgem, as the Data Processor, can access a Customer&rsquo;s Personal Data to deliver the Service as outlined in the Terms and Conditions of Use and the Data Processing Agreement.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":140,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-contrast="none">
                For further information on personal data processing, refer to the
              </span>
              <span data-contrast="none">
                {" "}
                Privacy Policy and the Data Processing Agreement.
              </span>
              <span data-ccp-props='{"134233117":false,"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559685":0,"335559737":0,"335559738":300,"335559739":300,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="9" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Intellectual Property Rights</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                9.1 Eventgem, its website, brand, and design are registered trademarks and copyrights of Eventgem Ltd., signifying the company's exclusive ownership. Please adhere to our terms by refraining from unauthorized actions such as modifying, renting, leasing, loaning, selling, distributing, or creating derivatives based on our website, brand, or services without explicit permission from Eventgem. This restriction applies to performance, service analysis, and competition-related activities.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                Organizer grants Platform Provider a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and distribute any Content provided by Organizer for the purpose of operating the Platform. The organizer represents and warrants that they have all necessary rights to such Content and that the use of such Content does not infringe on the rights of any third party.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":140,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="10" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    Cancellations, Refunds, and Alterations
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                10.1 <strong>Cancellation and Refund Policy:</strong> The Organizer must establish and clearly communicate a cancellation and refund policy for each event listed on the Platform. This policy must be compliant with local consumer protection laws and clearly detail the terms under which attendees can receive a refund, including time limits and any applicable fees.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                10.2 <strong>Event Changes:</strong> If the time, location, or significant aspects of an Event change, the Organizer must promptly update the Event's listing on the Platform and notify all affected ticket holders immediately. The Organizer agrees to handle all attendee inquiries and complaints related to such changes.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                10.3 <strong>In case of Event Cancellation,</strong> a non-refundable fee of{" "}
              </span>
              <span data-contrast="none">
                the platform fee and processing fee will be charged by Eventgem
                from the Organizer(s).&nbsp;&nbsp;
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":160,"335559739":300,"335559740":276,"335572071":0,"335572072":2,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":7,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                10.4 <strong>Platform's Role:</strong> The Platform may assist the Organizer in processing refunds and communicating changes but is not responsible for executing these activities. The Organizer retains all responsibility for compliance with its own policies and applicable laws.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="11" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Compliance with Laws</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                11.1 <strong>Legal Obligations:</strong> The Organizer agrees to conduct all activities associated with the Event in full compliance with all applicable laws, regulations, and ordinances.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":140,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                This includes but is not limited to, health and safety codes, anti-discrimination laws, and consumer protection laws.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":140,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                11.2 <strong>Licenses and Permits:</strong> The Organizer is responsible for obtaining any licenses, permits, or approvals required to host and sell tickets for the Event. The Organizer shall ensure that all aspects of the Event, including its promotion and execution, comply with applicable legal standards.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="12" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Third-Party Links and Content</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                12.1 <strong>External Links:</strong> The Platform may contain links to third-party websites or resources. Organizer acknowledges and agrees that the Platform Provider is not responsible or liable for: (i) the availability or accuracy of such sites or resources; or (ii) the content, products, or services on or available from such sites or resources.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                12.2 <strong>Link Disclaimers:</strong> The inclusion of any such link does not imply endorsement by the Platform Provider or any association with its operators. Organizer agrees to bear all risks arising from using any such websites or resources.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="13" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none">
                    {" "}
                    Term, Termination, and Suspension
                  </span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                13.1 <strong>Contract Term:</strong> This Agreement will remain in effect until terminated by either party. The Agreement may be terminated by either party without cause with thirty (30) days' written notice to the other party.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                13.2 <strong>Termination for Cause:</strong> The Platform may terminate this Agreement with immediate effect, without notice, in the event of a breach of this Agreement by the Organizer, illegal activity, or behavior that harms the Platform or its users.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                13.3 <strong>Effects of Termination:</strong> Upon termination, all rights granted to the Organizer under this Agreement will immediately cease. Organizers must cease all use of the Platform and delete any downloaded or stored information.
              </span>
              <span data-ccp-props='{"134233118":false,"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":140,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="14" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Dispute Resolution</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                14.1 <strong>Mediation and Arbitration:</strong> In the event of a dispute, the parties will attempt to resolve the conflict informally through mediation. If the matter cannot be resolved by mediation, then the dispute will be resolved by binding arbitration.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                The arbitration will be conducted in the jurisdiction where the Platform Provider is located.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                14.2 <strong>Governing Law:</strong> This Agreement shall be governed by and construed in accordance with the laws of USA, without regard to its conflict of law provisions.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="15" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> Amendments and Modifications</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                <strong>Amendments:</strong> The Platform Provider may modify or amend the terms of this Agreement from time to time. Any such modifications will be effective immediately upon posting on the Platform. Organizer's continued use of the Platform after any such changes shall constitute consent to such changes.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p><span>&nbsp;</span></p>
            <ol start="16" className="p-0 mb-1">
              <strong>
                <li
                  data-ccp-border-bottom="0px none #e3e3e3"
                  data-ccp-padding-bottom="0px"
                  data-ccp-border-between="0px none #e3e3e3"
                  data-ccp-padding-between="0px"
                >
                  <span data-contrast="none"> General Provisions</span>
                </li>
              </strong>
            </ol>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                16.1 <strong>Severability:</strong> If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will continue in effect, and an enforceable term will be substituted, reflecting our intent as closely as possible.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-bottom="0px none #e3e3e3"
              data-ccp-padding-bottom="0px"
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                16.2 <strong>Assignment:</strong> Organizer may not assign this Agreement without the prior written consent of the Platform Provider. However, the Platform Provider may assign this Agreement without restriction.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p
              data-ccp-border-between="0px none #e3e3e3"
              data-ccp-padding-between="0px"
            >
              <span data-contrast="none">
                16.3 <strong>Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties regarding its subject matter and supersedes any prior agreements or communications.
              </span>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559738":300,"335559739":300,"335559740":276,"335572071":0,"335572072":0,"335572073":14935011,"335572075":0,"335572076":0,"335572077":14935011,"335572079":0,"335572080":0,"335572081":14935011,"335572083":0,"335572084":0,"335572085":14935011,"335572087":0,"335572088":0,"335572089":14935011,"469789798":"none","469789802":"none","469789806":"none","469789810":"none","469789814":"none"}'>
                &nbsp;
              </span>
            </p>
            <p>
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":276}'>
                &nbsp;
              </span>
            </p>
            <p className="m-0">
              <span data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":160,"335559740":276}'>
                &nbsp;
              </span>
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
}
