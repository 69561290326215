import React, { useEffect, useState } from "react";
import "./css/LoginDM.css";
import newLogo from "../../Assets/newLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, setDoorManagerLink } from "../../redux/user";
import { devApi } from "../../utils/constants";
import axios from "axios";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function LoginDM() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    credentials: "",
    dmId: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dmId, setDmId] = useState();
  useEffect(() => {
    const queryString = window.location.search;
    dispatch(setDoorManagerLink(queryString));
    const urlParams = new URLSearchParams(queryString);
    setDmId(urlParams.get("dm"));
    if (!urlParams.get("dm")) {
      navigate("/invalidlink");
    }
  }, []);
  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!logindata.email) {
      dataIsValid = false;
      newErrors.email = "Username is required";
    } else {
      newErrors.email = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }
        const body = {
          username: logindata.email,
          password: logindata.password,
          door_management_id: dmId,
        };
        await axios
          .post(`${devApi}/api/dm/doorManagerLogin`, body)
          .then((res) => {
            if (res.data.success) {
              if (res.data.doorManager.admin_entry_closed) {
                setErrors({
                  ...errors,
                  error: "Entries are closed. Please contact your Admin",
                });
              } else {
                dispatch(
                  login({
                    token: res.data.token,
                    user: res.data.doorManager,
                  })
                );
                navigate(`/camerascanner`, {
                  state: { entry_closed: res.data.doorManager.entry_closed },
                });
              }
            } else {
              setErrors({
                ...errors,
                error: res.data.message,
              });
            }
          })
          .catch((error) => {
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return dmId ? (
    <div className="root-container d-flex align-items-center justify-content-center mt-10">
      <div className="logindm-page">
        <Link to="/">
          <img src={newLogo} alt="" />
        </Link>
        <p className="text-center p-2 m-2">
          <small className="title-dupage">
            Log in to start the door management
          </small>
          {/* Log in to start door management for{" "}
          <small className="title-dupage">DuPage County Fair</small> */}
        </p>

        <div className="form-box w-100">
          {errors.credentials && (
            <small className={"text-danger ml-2"}>{errors.credentials}</small>
          )}
          {errors.error && (
            <small className={"text-danger ml-2"}>{errors.error}</small>
          )}
          {errors.dmId && (
            <small className={"text-danger ml-2"}>{errors.dmId}</small>
          )}

          <div className="mb-2">
            <label htmlFor="" className="label">
              Username
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter Email"
              name="email"
              onChange={updateData}
              value={logindata.email}
            />
            <small className="text-danger ml-2">{errors.email}</small>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="label">
              Password
            </label>
            <div className="d-flex align-items-center signup-input">
              <input
                type={showPassword ? "text" : "password"}
                className="signup-input-password"
                placeholder="Enter Password"
                name="password"
                onChange={updateData}
                value={logindata.password}
              />
              <span
                className="show-hide"
                id="showHide"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="bi bi-eye show-hide"></i>
                ) : (
                  <i
                    className="bi bi-eye-slash show-hide"
                    id="togglePassword"
                  ></i>
                )}
              </span>
            </div>
            <small className="text-danger ml-2">{errors.password}</small>
          </div>
        </div>
        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center">
            <button className="login-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        ) : (
          <button
            className={
              logindata.email === "" || logindata.password === ""
                ? "login-btn-disable"
                : "login-btn"
            }
            onClick={logins}
          >
            Login
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
// {
//     "success": true,
//     "message": "QR code scanned successfully",
//     "attendee": {
//         "id": 51674,
//         "order_id": 50944,
//         "event_id": 29,
//         "ticket_id": 48,
//         "first_name": "Jai",
//         "last_name": "Kameih",
//         "email": "jaikameih@gmail.com",
//         "phone": null,
//         "private_reference_number": "UGOboYeuv3t6vE9",
//         "created_at": "2024-07-18 16:01:51",
//         "updated_at": "2024-07-23 04:08:47",
//         "deleted_at": null,
//         "is_cancelled": false,
//         "has_arrived": 1,
//         "arrival_time": "2024-07-23 04:08:47",
//         "account_id": 1,
//         "reference_index": 6,
//         "is_refunded": false,
//         "is_scaned": true,
//         "is_scaned_by": 3,
//         "is_scan_sucess": true,
//         "door_management_id": null,
//         "paper_ticket": 0
//     },
//     "door_manager": {
//         "id": 3,
//         "phone": null,
//         "email": "sumant.thakre@deployd.io",
//         "username": "sumant.thakre@deployd.io",
//         "event_id": 29,
//         "organiser_id": 12,
//         "account_id": 1,
//         "created_at": "2024-07-08 11:48:01",
//         "updated_at": "2024-07-23 04:06:51",
//         "door_management_id": 2,
//         "first_name": "Sumant",
//         "last_name": "Thakre",
//         "entry_closed": 0,
//         "logout": 0,
//         "admin_entry_closed": 1
//     },
//     "ticket": {
//         "id": 48,
//         "created_at": "2024-06-19 18:23:35",
//         "updated_at": "2024-07-22 11:33:31",
//         "deleted_at": null,
//         "edited_by_user_id": null,
//         "account_id": 1,
//         "order_id": null,
//         "event_id": 29,
//         "title": "Adults",
//         "description": "Ages 13 & up",
//         "price": "12.00",
//         "max_per_person": 30,
//         "min_per_person": 1,
//         "quantity_available": null,
//         "quantity_sold": 15147,
//         "start_sale_date": "2024-06-19 18:23:35",
//         "end_sale_date": null,
//         "sales_volume": "1764.00",
//         "organiser_fees_volume": "0.00",
//         "is_paused": 0,
//         "public_id": null,
//         "user_id": 1,
//         "sort_order": 0,
//         "is_hidden": 0,
//         "eventext_id": 22,
//         "early_bird": 0,
//         "full_price": "0.00"
//     }
// }
